<template>
    <div v-if="getSun || getMoon || getNight" class="row sun-moon-parent d-flex justify-content-center align-items-center">

        <div v-if="getSun" class="sun-section section d-flex justify-content-center align-items-center">
            <a v-if="hasImage" href="https://soho.nascom.nasa.gov/data/realtime/hmi_igr/512/" target=”_blank” rel="noreferrer">
                <div class="icon-size" :key="sunImageKey">
                    <img class="icon-size"
                         :src="computedSunImage"
                         @error="defaultSunImage"
                         alt="Sun"/>
                </div>
            </a>
            <div v-else class="sun icon-size"></div>
            <div class="desc">
                <div v-for="(event, index) in getEvents(getSun, 'sun')" :key="index">
                    <span class="field">{{ event.field }}</span>
                    <span class="preserve-whitespace time-value">{{ event.value }}</span>
                </div>
            </div>
        </div>

        <div v-if="getNight" class="night-section section d-flex justify-content-center align-items-center">
            <Night></Night>
            <div class="desc">
                <div v-for="(event, index) in getEvents(getNight, 'night')" :key="index">
                    <span class="field">{{ event.field }}</span>
                    <span class="preserve-whitespace time-value">{{ event.value }}</span>
                </div>
            </div>
        </div>

        <div v-if="getMoon" class="moon-section section d-flex justify-content-center align-items-center">
            <div>
                <Moon v-if="isChrome || isFirefox" class="icon-size" :illuminated="getMoon.illuminated" :phase="getMoon.phase"
                      :key="moonKey">
                </Moon>
                <div v-else>
                    <img class="icon-size" :src="require('@/assets/images/moon_full_original.jpg')" alt="Moon"/>
                </div>
            </div>
            <div class="desc">
                <div v-for="(event, index) in getEvents(getMoon, 'moon')" :key="index">
                    <span class="field">{{ event.field }}</span>
                    <span class="preserve-whitespace time-value">{{ event.value }}</span>
                </div>
                <span class="field">Osvětlení:</span>
                <span class="time-value">{{ getMoonIlluminated() }}</span>
            </div>
        </div>

    </div>
</template>

<script>

// Video on  https://www.youtube.com/watch?v=N8-p9bdYWqs&ab_channel=ChartJS

import {mapActions, mapGetters} from "vuex";
import {Utils} from "@/utils/Utils";
import {MoonPhases} from "@/config/moon-phases";
import Moon from "@/components/forecast/Moon";
// import {Utils} from "@/utils/Utils";
import platform from 'platform';
import Night from "@/components/forecast/Night.vue";
import {ApiConfig} from "@/config/api-config";

export default {
    name: "ForecastSunMoon",
    computed: {
        ApiConfig() {
            return ApiConfig
        },
        ...mapGetters('forecastsStore', ['getMoon', 'getMoonIllumination', 'getSun', 'getNight', 'shouldCenterOnMidnight']),
        ...mapGetters('staticResourcesStore', ['getCachedRealtimeSunImage']),
        isSafari() {
            console.log("is safari ...")
            const userAgent = platform.parse(navigator.userAgent);
            return userAgent.name === 'Safari' || userAgent.name === 'Mobile Safari';
        },
        isChrome() {
            if (navigator.userAgent.includes("Chrome")) {
                return true;
            }
            return false;
        },
        isFirefox() {
            if (navigator.userAgent.includes("Firefox")) {
                return true;
            }
            return false;
        },
        computedSunImage() { // has to be data or computed to be possible to use in the img tag
            if (this.getCachedRealtimeSunImage) {
                return URL.createObjectURL(this.getCachedRealtimeSunImage);
            }
            return this.defaultSunImage();
        },
        hasImage() {
            return this.getCachedRealtimeSunImage.size >= 0;
        },
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        getMoon: function (newValue) {
            this.moonKey++;
        },
        // eslint-disable-next-line no-unused-vars
        getSun: function (newValue) {
            this.sunImageKey++;
        },
    },
    data() {
        return {
            moonKey: 0,
            sunImageKey: 0,
            defaultSunImage: ApiConfig.sunAnimationUrl(),
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['']),
        getEvents(body, type) {
            if (body) {
                const events = body.events.map(e => {
                    const desc = e.event === "RISE" ? "Vychází:"
                        : e.event === "SET" ? "Zapadá:"
                                : e.event === "START" && e.type === "ASTRONOMICAL_TWILIGHT" ? "A. sou. od:"
                                : e.event === "END" && e.type === "ASTRONOMICAL_TWILIGHT" ? "A. sou. do:"
                                : e.event === "START" && e.type === "ASTRONOMICAL_NIGHT" ? "Noc od:"
                                : e.event === "END" && e.type === "ASTRONOMICAL_NIGHT" ? "Noc do:"
                                : "n/a";
                    // console.log(e.dateTime);
                    // console.log(new Date(e.dateTime));
                    const time = type === 'moon' && this.shouldCenterOnMidnight ? Utils.formatToTime_HHmm_ddMM_with_slash(new Date(e.dateTime))
                        : e.dateTime ? Utils.formatToTime_HHmm(new Date(e.dateTime))
                            : "-";
                    // console.log(time);
                    return {
                        "field": desc,
                        "value": time
                    }
                });
                return events;
            } else {
                return [];
            }
        },
        getMoonImage() {
            const moon = this.getMoon;
            if (moon) {
                return MoonPhases.getImage(moon.illuminated, moon.phase);
            } else {
                return null;
            }
        },
        getMoonIlluminated() {
            const moon = this.getMoon;
            if (moon) {
                return moon.illuminated + "%";
            } else {
                return null;
            }
        },
    },
    components: {
        Night,
        // eslint-disable-next-line vue/no-unused-components
        Moon
    }
}
</script>

<style scoped>

@media (max-width: 700px) {
    .desc {
        font-size: 8.5px;
        margin-left: 5px;
    }

    .sun-moon-parent {
        min-height: 45px
    }

    .icon-size {
        width: 37px;
        height: 37px;
    }

    .night-icon-size {
        width: 46px;
        height: 46px;
    }

    .sun-section {
        margin-right: 6px;
    }

    .night-section {
        margin-right: 6px;
    }

    .moon-section {
    }

    .field {
        font-weight: 600;
    }

}

@media (min-width: 700px) {
    .desc {
        font-size: 11px;
        margin-left: 5px;
    }

    .sun-moon-parent {
        min-height: 59px
    }

    .icon-size {
        width: 45px;
        height: 45px;
    }

    .night-icon-size {
        width: 60px;
        height: 60px;
    }

    .sun-section {
        margin-right: 12px;
    }

    .night-section {
        margin-right: 9px;
    }

    .field {
        font-weight: 500;
    }
}

.sun-moon-parent {
    margin-bottom: 30px;
}

.section {
    margin-top: 10px;
}

.sun {
    background: radial-gradient(circle at center, #ffdb58, #fdbc59);
    border-radius: 50%;
    /*margin-left: 15px;*/
}

.field {
    margin-right: 3px;
}

.time-value {
    /*font-family: "Courier New", Courier, monospace;*/
}

.preserve-whitespace {
    white-space: pre; /* Preserve whitespace within this element */
}

.full-moon {
    background-color: #ccc;
    border-radius: 50%;
    position: relative;
}

.full-moon::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(white, transparent 80%);
    border-radius: 50%;
    top: -10%;
    left: -10%;
}

</style>
